import React from 'react'
import { bigHeader } from '../styles/mixins'
import styled from '@emotion/styled'
import Container from '../components/container'

function Hero({ children, title }) {
  return (
    <HeroSection id="hero">
      <Container>
        <Wrapper>
          <Left>
            <H1>{title}</H1>
          </Left>
          <Right>{children}</Right>
        </Wrapper>
      </Container>
    </HeroSection>
  )
}

export default Hero

const HeroSection = styled.section`
  height: calc(100vh + 450px);
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  margin-bottom: 10rem;
`
const Left = styled.div`
  flex-basis: 25%;
  align-items: center;
  display: flex;
  padding-right: 5%;
`
const Right = styled.div`
  flex-basis: 70%;
  flex-grow: 0;
`
const H1 = styled.h1`
  ${bigHeader}
`
