import React from 'react'
import { MaxWidthContainer } from '../styles/mixins'
import styled from '@emotion/styled'

const Container = ({ children }) => {
  return <ContentContainer>{children}</ContentContainer>
}

export default Container

const ContentContainer = styled(MaxWidthContainer)`
	flex-direction:column;
`
