import React, {useState, useEffect, useContext} from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import styled from "@emotion/styled"
import {css} from "@emotion/core"
import { graphql } from 'gatsby'
import Hero from '../containers/hero'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import VideoModule from '../components/modules/video'
import { subheader, pStyle } from '../styles/mixins'
import theme from '../styles/theme'
import Wrapper from "../components/HorizontalScroll/Wrapper"
import Container from "../components/HorizontalScroll/Container"
import HeaderContainer from "../components/HorizontalScroll/HeaderContainer"
import SidewaysContainer from "../components/HorizontalScroll/SidewaysContainer"
import H1 from "../components/Headers/H1"
import {AppContext} from "../context/stateProvider"
import HorizontalScroll from '@oberon-amsterdam/horizontal';


export const query = graphql`
  query ContactPageQuery {
    page: sanityPageTemplate(_id: {eq: "bb5bb3c8-7ce0-41cd-9af1-22f3de59f24e"}) {
      title
      header
      _rawBody
			video {
				asset {
					_key
					_type
					status
					assetId
					playbackId
					filename
					thumbTime
				}
			}
    }
  }
`

const ContactPage = props => {
  const { data, errors } = props
  const [container, setContainer] = useState()
  const { appState, setAppState } = useContext(AppContext)

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page
  // const video = _.get(data, "page.video.asset")

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }
 const heading = _.get(data, 'page.header')

  useEffect(() => {
    const scrollContainer = document.querySelector('.container')
    console.log("isMobile", appState.isMobile)
    if (appState.isMobile === false) {
      new HorizontalScroll({ container: scrollContainer });
    }
  }, [appState]);


  return (
    <Layout darkMode={true}>
      <SEO title={page.title} />
      <Container>
      <Wrapper
          className="container"
          ref={ref => {
            setContainer(ref)
          }}
        > <SidewaysContainer>
            <HeaderContainer css={css `max-width: 40rem;`}>
              <H1>{heading}</H1>
            </HeaderContainer>
            <Center>
              <H2>Let us make your wedding dreams come true.</H2>
              <P>For more information or if you’d like Umbrella Factor to make your event memorable, contact us.</P>
              <ListWrapper>
                <Category> 
                  <H3>Email</H3>
                  <ListItem><ExternalLink href="mailto:info@umbrellafactor.us">info@umbrellafactor.us</ExternalLink></ListItem>
                </Category>
                <Category> 
                  <H3>Phone</H3>
                  <ListItem><ExternalLink href="tel:813-992-8869">813-992-8869</ExternalLink></ListItem>
                </Category>
                  <Category> 
                  <H3>Tele/Fax</H3>
                  <ListItem><ExternalLink href="tel:813-428-6346">813-428-6346</ExternalLink></ListItem>
                </Category>
              </ListWrapper>
            </Center>
        </SidewaysContainer>
      </Wrapper>
			</Container>
    </Layout>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default ContactPage


const H2 = styled.h2`
  ${subheader}
  line-height: 1.3;
  text-align: left;
  color: ${theme.colors.white};
`
const ListWrapper = styled.div `
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  gap: 3rem;

	@media(min-width:${theme.breakpoints.small}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: none;
	}
`

const Category = styled.ul``

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  font-family: ${theme.fonts.body};
  line-height: 1.6;
  font-weight: 300;
  color: ${theme.colors.white};
`
const H3 = styled.h3`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  color: ${theme.colors.white};
  font-family: ${theme.fonts.body};
  text-transform: uppercase;
`


const Center = styled.div`
  border: solid 1px ${theme.colors.white};
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${theme.colors.black};
  margin-bottom: 3rem;

	@media(min-width:${theme.breakpoints.small}) {
    min-width: 36rem;
    margin-right: 10rem;
    padding: 12vh 8rem 12vh 5.125rem;
    margin-bottom: 0;
  }
`

const P = styled.div`
	${pStyle}
	color:${theme.colors.white};
	margin-top:20px;
	text-align: left;
  margin-bottom: 50px;
`
const ExternalLink = styled.a `
  text-decoration: none;
  color: ${theme.colors.white};
`
